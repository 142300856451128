import React from 'react';
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CardActions,
    Button,
    Box,
    Chip,
    Divider,
} from '@mui/material';
import { LanguageContext } from '../../LanguageContext';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const tiers = {
    EN: [
        {
            title: 'Free',
            price: '0',
            description: [
                '3,000 word tokens',
                '3 image tokens',
                'WordPress integration',
            ],
            buttonText: 'Sign up for free',
            buttonVariant: 'outlined',
            link: 'https://app.tenno.ai/register',
        },
        {
            title: 'Entry',
            subheader: 'Recommended',
            price: '960',
            description: [
                'Access to more than 100 templates',
                'Exclusive community access',
                '36,000 word tokens',
                '16 image tokens',
                'WordPress integration',
            ],
            buttonText: 'Start now',
            buttonVariant: 'contained',
            link: 'https://app.tenno.ai/register',
        },
        {
            title: 'Professional',
            price: '3840',
            description: [
                'Access to more than 100 templates',
                'Exclusive community access',
                '140,000 word tokens',
                '64 image tokens',
                'WordPress integration',
            ],
            buttonText: 'Sign up now',
            buttonVariant: 'outlined',
            link: 'https://app.tenno.ai/register',
        },
    ],
    JP: [
        {
            title: '無料',
            price: '0',
            description: [
                '3,000単語トークン',
                '3画像トークン',
                'WordPress統合',
            ],
            buttonText: '無料で登録',
            buttonVariant: 'outlined',
            link: 'https://app.tenno.ai/register',
        },
        {
            title: 'エントリー',
            subheader: 'おすすめ',
            price: '960',
            description: [
                '100以上のテンプレートへのアクセス',
                '限定コミュニティの参加',
                '36,000単語トークン',
                '16画像トークン',
                'WordPress統合',
            ],
            buttonText: '今すぐ始める',
            buttonVariant: 'contained',
            link: 'https://app.tenno.ai/register',
        },
        {
            title: 'プロフェッショナル',
            price: '3840',
            description: [
                '100以上のテンプレートへのアクセス',
                '限定コミュニティの参加',
                '140,000単語トークン',
                '64画像トークン',
                'WordPress統合',
            ],
            buttonText: '今すぐ登録',
            buttonVariant: 'outlined',
            link: 'https://app.tenno.ai/register',
        },
    ],
};

const Pricing = () => {
    const { language } = React.useContext(LanguageContext);

    return (
        <Container id="pricing" sx={{ py: { xs: 8, sm: 16 } }}>
            <Typography
                component="h2"
                variant="h4"
                color="text.primary"
                textAlign="left"
                mb={8}
            >
                {language === 'EN' ? 'Pricing' : '価格'}
            </Typography>
            <Grid container spacing={3} alignItems="center" justifyContent="center">
                {tiers[language].map((tier) => (
                    <Grid
                        item
                        key={tier.title}
                        xs={12}
                        sm={tier.title === 'プロフェッショナル' ? 12 : 6}
                        md={4}
                    >
                        <Card
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 4,
                                border: tier.subheader ? '1px solid' : undefined,
                                borderColor: tier.subheader ? 'primary.main' : undefined,
                                background: tier.subheader
                                    ? 'linear-gradient(#033363, #021F3B)'
                                    : undefined,
                            }}
                        >
                            <CardContent>
                                <Box
                                    sx={{
                                        mb: 1,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        color: tier.subheader ? 'grey.100' : '',
                                    }}
                                >
                                    <Typography component="h3" variant="h6">
                                        {tier.title}
                                    </Typography>
                                    {tier.subheader && (
                                        <Chip
                                            icon={<AutoAwesomeIcon />}
                                            label={tier.subheader}
                                            size="small"
                                            sx={{
                                                background: (theme) =>
                                                    theme.palette.mode === 'light' ? '' : 'none',
                                                backgroundColor: 'primary.contrastText',
                                                '& .MuiChip-label': {
                                                    color: 'primary.dark',
                                                },
                                                '& .MuiChip-icon': {
                                                    color: 'primary.dark',
                                                },
                                            }}
                                        />
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                        color: tier.subheader ? 'grey.50' : undefined,
                                    }}
                                >
                                    <Typography component="h3" variant="h2">
                                        ¥{tier.price}
                                    </Typography>
                                    <Typography component="h3" variant="h6">
                                        &nbsp; {language === 'EN' ? 'per month' : '/ 月'}
                                    </Typography>
                                </Box>
                                <Divider
                                    sx={{
                                        my: 2,
                                        opacity: 0.2,
                                        borderColor: 'grey.500',
                                    }}
                                />
                                {tier.description.map((line) => (
                                    <Box
                                        key={line}
                                        sx={{
                                            py: 1,
                                            display: 'flex',
                                            gap: 1.5,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CheckCircleRoundedIcon
                                            sx={{
                                                width: 20,
                                                color: tier.subheader ? 'primary.light' : 'primary.main',
                                            }}
                                        />
                                        <Typography
                                            component="text"
                                            variant="subtitle2"
                                            sx={{
                                                color: tier.subheader ? 'grey.200' : undefined,
                                            }}
                                        >
                                            {line}
                                        </Typography>
                                    </Box>
                                ))}
                            </CardContent>
                            <CardActions>
                                <Button
                                    fullWidth
                                    variant={tier.buttonVariant}
                                    color="primary"
                                    href={tier.link}
                                >
                                    {tier.buttonText}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Pricing;
