import React from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';

const Footer = ({ showLegalDisclosure }) => {
  const theme = useTheme();
  const iconColor = theme.palette.mode === 'light' ? 'black' : 'white';

  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        bgcolor: 'background.default',
        color: 'text.primary',
        p: 1,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        zIndex: 3,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 2 }}>
        <Box className="footer-icon" sx={{ mx: 1, fontSize: '10px', color: iconColor }}>
          <MuiLink href="https://www.facebook.com/profile.php?id=61561085527978" target="_blank" rel="noopener">
            <FacebookIcon fontSize="small" sx={{ color: iconColor }} />
          </MuiLink>
        </Box>
        <Box className="footer-icon" sx={{ mx: 1, fontSize: '10px', color: iconColor }}>
          <MuiLink href="https://x.com/AI__Daisuke" target="_blank" rel="noopener">
            <XIcon fontSize="small" sx={{ color: iconColor }} />
          </MuiLink>
        </Box>
      </Box>
      <Typography variant="body2" component="p" sx={{ fontSize: '10px' }}>
        &copy; {new Date().getFullYear()} Tenno AI. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
