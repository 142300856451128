import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { LanguageContext } from '../../LanguageContext';

const Hero = () => {
    const { language } = useContext(LanguageContext);

    const content = {
        EN: {
            title: 'Elevate Your Enterprise with Tenno AI',
            subtitle: 'Enhance Business Efficiency with Customizable AI Tools for Enterprises',
        },
        JP: {
            title: 'Tenno AIで企業を進化',
            subtitle: '企業のためのカスタマイズAIツールで、業務効率を飛躍的に向上',
        },
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: { xs: '70vh', sm: '80vh' }, // スマホで70vh、その他で80vh
                background: `url("/assets/images/business-hero.webp") no-repeat center center`,
                backgroundSize: 'cover',
                color: 'white',
                textAlign: 'center',
                flexDirection: 'column',
                padding: 2,
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1, // フィルターの優先度を適度に設定
                },
                '& > *': {
                    zIndex: 2, // コンテンツの優先度をフィルターより高く設定
                },
            }}
        >
            <Typography 
                variant="h2" 
                component="h1" 
                sx={{ 
                    mb: 2,
                    fontSize: { xs: '2rem', sm: '3rem', md: '4rem' }
                }}
            >
                {content[language].title}
            </Typography>
            <Typography 
                variant="h6" 
                component="p"
                sx={{ 
                    fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' }
                }}
            >
                {content[language].subtitle}
            </Typography>
        </Box>
    );
};

export default Hero;
