import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const VideoSectionContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px 0', // 上下に余白を追加
}));

const Video = styled('video')(({ theme }) => ({
    maxWidth: '1024px', // 動画のサイズを調整
    height: 'auto',
    border: '1px solid #ccc', // 必要に応じて枠を追加
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // 影を追加して見栄えを良くする
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: '30px',
    padding: '20px 50px',
    fontSize: '24px',
    backgroundColor: '#007BFF',
    color: '#fff',
    borderRadius: '50px',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
        backgroundColor: '#0056b3',
        transform: 'scale(1.1)',
    },
}));

const ButtonText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    letterSpacing: '1.5px',
}));

const VideoSection = () => {
    return (
        <VideoSectionContainer>
            <Video controls>
                <source src="/assets/videos/about-service.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </Video>
            <StyledButton
                href="https://forms.gle/8b29XqvxDDqhoa4h6"
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
            >
                <ButtonText>
                    今すぐお問い合わせ
                </ButtonText>
            </StyledButton>
        </VideoSectionContainer>
    );
};

export default VideoSection;
