// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import TennoPersonal from './tennoPersonal';
import TennoBusiness from './tennoBusiness';

const theme = createTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <Routes>
        <Route path="/*" element={<App />} />
        <Route path="/personal" element={<TennoPersonal />} />
        <Route path="/business/*" element={<TennoBusiness />} />
      </Routes>
    </Router>
  </ThemeProvider>
);