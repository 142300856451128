import React, { useEffect, useState, useContext } from 'react';
import { Box, Button, Typography, Link as MuiLink } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import { LanguageContext } from '../LanguageContext';
import './Hero.css';

const Hero = () => {
    const theme = useTheme();
    const [isLightMode, setIsLightMode] = useState(theme.palette.mode === 'light');
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        setIsLightMode(theme.palette.mode === 'light');
    }, [theme.palette.mode]);

    useEffect(() => {
        const isFirefox = typeof InstallTrigger !== 'undefined';
        const words = "TENNOTENNOTENNO";
        let ANGLE = 0;
        const ANIMATION_DURATION = 4000;

        const animation = () => {
            ANGLE += 1;
            document.querySelectorAll(".spiral *").forEach((el, i) => {
                const translateY = Math.sin((ANGLE + i) * (Math.PI / 180)) * 100;
                const scale = Math.cos((ANGLE + i) * (Math.PI / 180)) * 0.5 + 0.5;
                const offset = parseInt(el.dataset.offset, 10);
                const delay = i * (ANIMATION_DURATION / 16) - offset;

                setTimeout(() => {
                    el.style.transform = `translateY(${translateY}px) scale(${scale})`;
                }, delay);
            });

            requestAnimationFrame(animation);
        };

        words.split("").forEach((char, i) => {
            const createElement = (offset) => {
                const div = document.createElement("div");
                div.innerText = char;
                div.classList.add("character");
                div.setAttribute("data-offset", offset);
                div.style.animationDelay = `-${i * (ANIMATION_DURATION / 16) - offset}ms`;
                return div;
            };

            document.querySelector("#spiral").append(createElement(0));
            document.querySelector("#spiral2").append(createElement((isFirefox ? 1 : -1) * (ANIMATION_DURATION / 2)));
        });

        if (isFirefox) {
            animation();
        } else {
            document.querySelector(".spiral").style.animation = `spiral ${ANIMATION_DURATION / 1000}s linear infinite`;
        }
    }, []);

    return (
        <Box
            className="hero-container"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
                textAlign: 'center',
                position: 'relative',
            }}
        >
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Box id="spiral" className={`spiral ${isLightMode ? 'light-mode' : ''}`} />
                <Box id="spiral2" className={`spiral ${isLightMode ? 'light-mode' : ''}`} />

                <Box sx={{ mt: 20 }}>
                    <Button
                        variant="contained"
                        component={Link}
                        to="/personal"
                        sx={{
                            bgcolor: 'text.primary',
                            color: 'background.default',
                            '&:hover': {
                                bgcolor: 'background.paper',
                                color: 'text.primary',
                            },
                        }}
                    >
                        {language === 'EN' ? 'START' : 'はじめる'}
                    </Button>
                </Box>
            </Box>
            <Footer />
        </Box>
    );
};

const Footer = () => {
    const theme = useTheme();
    const iconColor = theme.palette.mode === 'light' ? 'black' : 'white';

    return (
        <Box
            component="footer"
            className="footer"
            sx={{
                width: '100%',
                bgcolor: 'background.default',
                color: 'text.primary',
                p: 2,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 'auto',
                boxSizing: 'border-box',
                zIndex: 3,
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 2 }}>
                <Box className="footer-icon" sx={{ mx: 1, fontSize: '10px', color: iconColor }}>
                    <MuiLink href="https://www.facebook.com/profile.php?id=61561085527978" target="_blank" rel="noopener">
                        <FacebookIcon fontSize="small" sx={{ color: iconColor }} />
                    </MuiLink>
                </Box>
                <Box className="footer-icon" sx={{ mx: 1, fontSize: '10px', color: iconColor }}>
                    <MuiLink href="https://x.com/AI__Daisuke" target="_blank" rel="noopener">
                        <XIcon fontSize="small" sx={{ color: iconColor }} />
                    </MuiLink>
                </Box>
            </Box>
            <Typography variant="body2" component="p" sx={{ fontSize: '10px' }}>
                &copy; {new Date().getFullYear()} Tenno AI. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Hero;
