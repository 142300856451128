import React from 'react';
import {
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LanguageContext } from '../../LanguageContext';

const faqData = {
    EN: [
        {
            question: 'How does Tenno AI generate insights?',
            answer:
                'Our AI analyzes input data and uses advanced algorithms to provide personalized suggestions.',
        },
        {
            question: 'Can I try Tenno AI for free?',
            answer:
                'Yes, you can experience the benefits of Tenno AI with our free Personal Plan. For enterprise solutions, we offer customized plans tailored to your business needs.',
        },
        {
            question: 'Is my data secure?',
            answer:
                'Absolutely. We prioritize privacy and protect your information using the latest security measures.',
        },
        {
            question: 'How do I contact support?',
            answer:
                'You can reach our support team through the app or by visiting our contact page.',
        },
        {
            question: 'What features does Tenno AI offer?',
            answer:
                'Tenno AI offers a range of features. Please see the Features section for more details.',
        },
        {
            question: 'What options are available for enterprise plans?',
            answer:
                'Enterprise plans offer customized AI solutions, access to all features, dedicated support, and unlimited tokens on a pay-as-you-go basis.',
        },
        {
            question: 'Can Tenno AI be deployed on our own servers?',
            answer:
                'Yes, Tenno AI can be deployed on your own servers, depending on your server specifications, providing you with complete control and security over your data and operations.',
        },
        {
            question: 'How can Tenno AI help with new business ideas?',
            answer:
                'Tenno AI can assist in brainstorming, validating, and refining new business ideas by analyzing trends and providing actionable insights.',
        },
    ],
    JP: [
        {
            question: 'Tenno AIはどのように提案を生成しますか？',
            answer:
                'Tenno AIは入力データを分析し、高度なアルゴリズムを使用して個別化された提案を提供します。',
        },
        {
            question: 'Tenno AIを無料で試せますか？',
            answer:
                'はい、無料のパーソナルプランでTenno AIのメリットを体験できます。エンタープライズソリューションについては、ビジネスニーズに合わせたカスタマイズプランを提供します。',
        },
        {
            question: '私のデータは安全ですか？',
            answer:
                'もちろんです。プライバシーを最優先し、最新のセキュリティ対策を用いて情報を保護しています。',
        },
        {
            question: 'サポートに連絡するにはどうすればいいですか？',
            answer:
                'アプリ内またはお問い合わせページからサポートチームにご連絡いただけます。',
        },
        {
            question: 'Tenno AIにはどんな機能がありますか？',
            answer:
                'Tenno AIは、さまざまな機能を提供しています。詳細は機能のセクションをご覧ください。',
        },
        {
            question: 'エンタープライズプランにはどのようなオプションがありますか？',
            answer:
                'エンタープライズプランでは、カスタマイズされたAIソリューション、全機能へのアクセス、専任サポート、無制限の従量課金制トークンが提供されます。',
        },
        {
            question: 'Tenno AIを自社サーバーに導入することは可能ですか？',
            answer:
                'はい、Tenno AIは自社サーバーに導入することが可能で、サーバーのスペックに応じて、データと運用の完全な制御とセキュリティを提供します。',
        },
        {
            question: 'Tenno AIは新規事業アイディアのサポートにどう役立ちますか？',
            answer:
                'Tenno AIは、トレンドの分析や行動可能な洞察の提供によって、新規事業のアイデアのブレインストーミング、検証、洗練を支援します。',
        },
    ],
};

const FAQ = () => {
    const [expanded, setExpanded] = React.useState(false);
    const { language } = React.useContext(LanguageContext);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container
            id="faq"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
            }}
        >
            <Typography
                component="h2"
                variant="h4"
                color="text.primary"
                textAlign="left"
                mb={4}
            >
                {language === 'EN' ? 'Frequently Asked Questions' : 'よくある質問'}
            </Typography>
            <Box sx={{ width: '100%' }}>
                {faqData[language].map((item, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}d-content`}
                            id={`panel${index}d-header`}
                        >
                            <Typography component="h3" variant="subtitle1">
                                {item.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ maxWidth: '100%' }}>
                                {item.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Container>
    );
};

export default FAQ;
