import React from 'react';
import {
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LanguageContext } from '../../LanguageContext';

const faqData = {
    EN: [
        {
            question: 'How does Tenno AI generate content?',
            answer:
                'Tenno AI uses advanced algorithms to analyze input data and generate high-quality content tailored to your needs.',
        },
        {
            question: 'Can I try Tenno AI for free?',
            answer:
                'Yes, we offer a free plan that includes basic features.',
        },
        {
            question: 'Is my data secure with Tenno AI?',
            answer:
                'Absolutely. We prioritize your privacy and protect your data with the latest security measures.',
        },
        {
            question: 'What are the differences between the plans?',
            answer: 'Please refer to the "Plans" section on our website for detailed information about the plans.'
        },
        {
            question: 'How can I contact support?',
            answer:
                'You can reach our support team through the app or by visiting our contact page at https://app.tenno.ai/contact.',
        },
        {
            question: 'Does Tenno AI integrate with WordPress?',
            answer:
                'Yes, all our plans include seamless WordPress integration to help you auto-generate and publish content easily.',
        },
    ],
    JP: [
        {
            question: 'Tenno AIはどのようにコンテンツを生成しますか？',
            answer:
                'Tenno AIは高度なアルゴリズムを使用して、入力データを分析し、あなたのニーズに合わせた高品質なコンテンツを生成します。',
        },
        {
            question: 'Tenno AIを無料で試せますか？',
            answer:
                'はい、基本機能を提供する無料プランをご用意しています。',
        },
        {
            question: 'Tenno AIは私のデータを安全に保管しますか？',
            answer:
                'もちろんです。私たちはプライバシーを最優先し、最新のセキュリティ対策を用いてデータを保護しています。',
        },
        {
            question: 'プランの違いは何ですか？',
            answer: '詳細については、本サイトの「プラン」セクションをご覧ください。'
        },
        {
            question: 'サポートに連絡するにはどうすればいいですか？',
            answer:
                'アプリ内またはhttps://app.tenno.ai/contactのお問い合わせページからサポートチームにご連絡いただけます。',
        },
        {
            question: 'Tenno AIはWordPressと連携しますか？',
            answer:
                'はい、すべてのプランにWordPressとのシームレスな統合が含まれており、コンテンツの自動生成と公開を簡単に行うことができます。',
        },
    ],
};

const FAQ = () => {
    const [expanded, setExpanded] = React.useState(false);
    const { language } = React.useContext(LanguageContext);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container
            id="faq"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
            }}
        >
            <Typography
                component="h2"
                variant="h4"
                color="text.primary"
                textAlign="left"
                mb={4}
            >
                {language === 'EN' ? 'Frequently Asked Questions' : 'よくある質問'}
            </Typography>
            <Box sx={{ width: '100%' }}>
                {faqData[language].map((item, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}d-content`}
                            id={`panel${index}d-header`}
                        >
                            <Typography component="h3" variant="subtitle1">
                                {item.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ maxWidth: '100%' }}>
                                {item.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Container>
    );
};

export default FAQ;
