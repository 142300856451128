import React, { useState, useContext } from 'react';
import {
    Container,
    Typography,
    Grid,
    Box,
    Card,
    useTheme,
    Button,
} from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GroupIcon from '@mui/icons-material/Group';
import { LanguageContext } from '../../LanguageContext';

const items = {
    EN: [
        {
            icon: <InsightsIcon />,
            title: 'Custom AI Training for Enterprises',
            description: 'Train AI models specifically tailored to your business needs.',
            image: '/assets/images/feature-1.png',
        },
        {
            icon: <TrendingUpIcon />,
            title: 'Press Release Creation',
            description: 'Generate professional press releases quickly and efficiently.',
            image: '/assets/images/feature-2.png',
        },
        {
            icon: <CheckCircleOutlineIcon />,
            title: 'New Business Idea Support',
            description: 'Get AI-driven support for brainstorming and developing new business ideas.',
            image: '/assets/images/feature-3.png',
        },
        {
            icon: <GroupIcon />,
            title: 'Comprehensive Business Support',
            description: 'Support for HR, administration, development leaders, finance, sales, marketing, customer service, and IT with custom AI solutions.',
            image: '/assets/images/feature-4.png',
        },
    ],
    JP: [
        {
            icon: <InsightsIcon />,
            title: '企業用にAIをトレーニング',
            description: 'ビジネスニーズに特化したAIモデルをトレーニングします。',
            image: '/assets/images/feature-1.png',
        },
        {
            icon: <TrendingUpIcon />,
            title: 'プレスリリースの作成',
            description: 'プロフェッショナルなプレスリリースを迅速かつ効率的に生成します。',
            image: '/assets/images/feature-2.png',
        },
        {
            icon: <CheckCircleOutlineIcon />,
            title: '新規事業アイディアのサポート',
            description: 'AIによるブレインストーミングや新規事業アイディアの開発をサポートします。',
            image: '/assets/images/feature-3.png',
        },
        {
            icon: <GroupIcon />,
            title: '総合的なビジネスサポート',
            description: '総務、人事、開発リーダー、財務、営業、マーケティング、カスタマーサービス、ITなど、カスタムAIソリューションで事業をサポートします。',
            image: '/assets/images/feature-4.png',
        },
    ],
};


const Features = () => {
    const theme = useTheme();
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const { language } = useContext(LanguageContext);

    const handleItemClick = (index) => {
        setSelectedItemIndex(index);
    };

    const selectedFeature = items[language] && items[language][selectedItemIndex];

    return (
        <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
            <Grid container spacing={6} alignItems="stretch">
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                    >
                        <Box>
                            <Typography component="h2" variant="h4" color="text.primary">
                                {language === 'EN' ? 'Features' : '機能'}
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
                                {language === 'EN'
                                    ? 'Our solutions offer automation of business processes and advanced security features, achieving both efficiency and safety. Custom-trained AI tailored specifically for your company meets your business needs.'
                                    : '業務プロセスの自動化や高度なセキュリティ機能を提供し、効率と安全性を同時に実現します。企業専用にトレーニングされたカスタムAIがビジネスニーズに応えます。'}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                width: '100%',
                            }}
                        >
                            {items[language] && items[language].map(({ icon, title, description }, index) => (
                                <Card
                                    key={index}
                                    variant="outlined"
                                    component={Button}
                                    onClick={() => handleItemClick(index)}
                                    sx={{
                                        p: 3,
                                        height: 'fit-content',
                                        width: '100%',
                                        background: 'none',
                                        backgroundColor:
                                            selectedItemIndex === index ? 'action.selected' : undefined,
                                        borderColor: (theme) => {
                                            if (theme.palette.mode === 'light') {
                                                return selectedItemIndex === index
                                                    ? 'primary.light'
                                                    : 'grey.200';
                                            }
                                            return selectedItemIndex === index
                                                ? 'primary.dark'
                                                : 'grey.800';
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            textAlign: 'left',
                                            alignItems: 'center',
                                            gap: 2.5,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                color: (theme) => {
                                                    if (theme.palette.mode === 'light') {
                                                        return selectedItemIndex === index
                                                            ? 'primary.main'
                                                            : 'grey.300';
                                                    }
                                                    return selectedItemIndex === index
                                                        ? 'primary.main'
                                                        : 'grey.700';
                                                },
                                            }}
                                        >
                                            {icon}
                                        </Box>
                                        <Box sx={{ textTransform: 'none' }}>
                                            <Typography
                                                color="text.primary"
                                                variant="body2"
                                                fontWeight="bold"
                                            >
                                                {title}
                                            </Typography>
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                                sx={{ my: 0.5 }}
                                            >
                                                {description}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            p: 4,
                            backgroundColor:
                                theme.palette.mode === 'light' ? 'grey.100' : 'grey.900',
                            borderRadius: 2,
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {selectedFeature && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    gap: 2.5,
                                }}
                            >
                                <img
                                    src={selectedFeature.image}
                                    alt={selectedFeature.title}
                                    style={{ maxWidth: '100%', maxHeight: '400px' }}
                                />
                                <Box>
                                    <Typography
                                        color="text.primary"
                                        variant="h5"
                                        fontWeight="bold"
                                        gutterBottom
                                    >
                                        {selectedFeature.title}
                                    </Typography>
                                    <Typography color="text.secondary" variant="body1">
                                        {selectedFeature.description}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Features;
