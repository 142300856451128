import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { LanguageContext } from '../../LanguageContext';

const Hero = () => {
    const { language } = useContext(LanguageContext);

    const content = {
        EN: {
            title: 'Welcome to Tenno AI',
            subtitle: 'Your New Right Hand, Supporting Your Daily Operations as a Partner in Achieving Success',
        },
        JP: {
            title: 'Tenno AIへようこそ',
            subtitle: 'あなたの新しい右腕として、成功を実現する為の日々の業務をサポート。',
        },
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: { xs: '60vh', sm: '80vh' }, // スマホで70vh、その他で80vh
                background: `url("/assets/images/personal-hero.webp") no-repeat center center`,
                backgroundSize: 'cover',
                color: 'white',
                textAlign: 'center',
                flexDirection: 'column',
                padding: 2,
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1,
                },
                '& > *': {
                    zIndex: 2,
                },
            }}
        >
            <Typography 
                variant="h2" 
                component="h1" 
                sx={{ 
                    mb: 2,
                    fontSize: { xs: '2rem', sm: '3rem', md: '4rem' }
                }}
            >
                {content[language].title}
            </Typography>
            <Typography 
                variant="h6" 
                component="p"
                sx={{ 
                    fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
                    display: { xs: 'block', sm: 'none' } // スマホで表示
                }}
            >
                {language === 'EN' 
                    ? <>
                        Your New Right Hand,<br />
                        Supporting Your Daily Operations as a Partner in Achieving Success
                      </>
                    : <>
                        あなたの新しい右腕として、<br />
                        成功を実現する為の日々の業務をサポート
                      </>
                }
            </Typography>
            <Typography 
                variant="h6" 
                component="p"
                sx={{ 
                    fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
                    display: { xs: 'none', sm: 'block' } // スマホ以外で表示
                }}
            >
                {language === 'EN' 
                    ? 'Your New Right Hand, Supporting Your Daily Operations as a Partner in Achieving Success'
                    : 'あなたの新しい右腕として、成功を実現する為の日々の業務をサポート。'
                }
            </Typography>
        </Box>
    );
};

export default Hero;
