import React, { useState, useContext } from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Card,
} from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GroupIcon from '@mui/icons-material/Group';
import { LanguageContext } from '../../LanguageContext';

const items = {
    EN: [
        {
            icon: <InsightsIcon />,
            title: 'Customized AI Partner',
            description: 'Personalized support and insights tailored to your business needs.',
            image: '/assets/images/feature-1.png',
        },
        {
            icon: <TrendingUpIcon />,
            title: 'WordPress Integration & Auto Article Generation',
            description: 'Seamlessly integrate with WordPress to auto-generate high-quality articles.',
            image: '/assets/images/feature-2.png',
        },
        {
            icon: <CheckCircleOutlineIcon />,
            title: 'Extensive Templates',
            description: 'Access a variety of templates for different business needs.',
            image: '/assets/images/feature-3.png',
        },
        {
            icon: <GroupIcon />,
            title: 'Learning Community',
            description: 'Join a community to learn and share AI knowledge.',
            image: '/assets/images/feature-4.png',
        },
    ],
    JP: [
        {
            icon: <InsightsIcon />,
            title: 'カスタマイズされたAIパートナー',
            description: 'ビジネスニーズに合わせたパーソナライズされたサポートと洞察。',
            image: '/assets/images/feature-1.png',
        },
        {
            icon: <TrendingUpIcon />,
            title: 'WordPress統合と自動記事生成',
            description: 'WordPressとシームレスに統合し、高品質な記事を自動生成します。',
            image: '/assets/images/feature-2.png',
        },
        {
            icon: <CheckCircleOutlineIcon />,
            title: '豊富なテンプレート',
            description: 'さまざまなビジネスニーズに対応するテンプレートにアクセスできます。',
            image: '/assets/images/feature-3.png',
        },
        {
            icon: <GroupIcon />,
            title: '学習コミュニティ',
            description: 'コミュニティに参加して、AIの知識を学び共有しましょう。',
            image: '/assets/images/feature-4.png',
        },
    ],
};

const Features = () => {
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const { language } = useContext(LanguageContext);

    const handleItemClick = (index) => {
        setSelectedItemIndex(index);
    };

    const selectedFeature = items[language] && items[language][selectedItemIndex];

    return (
        <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: 'flex-start',
                    mb: 4,
                }}
            >
                <Box sx={{ flex: 1, textAlign: 'left', mb: { xs: 4, md: 0 }, pr: { md: 4 }, display: 'flex', flexDirection: 'column' }}>
                    <Typography component="h2" variant="h4" color="text.primary">
                        {language === 'EN' ? 'Features' : '機能'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ textAlign: { xs: 'left', md: 'left' } }}>
                        {language === 'EN'
                            ? 'Discover key features that drive business success. Experience a range of functionalities including customized AI, WordPress integration, automated article generation, and a learning community.'
                            : 'ビジネス成功を支える主要機能をご紹介。カスタマイズAI、WordPress統合、自動記事生成、学習コミュニティなど、多彩な機能を体験ください。'}
                    </Typography>
                    <Box sx={{ display: { xs: 'none', md: 'block' }, mt: 4, flexGrow: 1 }}>
                        {items[language] && items[language].map(({ icon, title, description }, index) => (
                            <Button
                                key={index}
                                variant="outlined"
                                onClick={() => handleItemClick(index)}
                                sx={{
                                    p: 2,
                                    background: 'none',
                                    backgroundColor: selectedItemIndex === index ? 'action.selected' : undefined,
                                    borderColor: selectedItemIndex === index ? 'primary.light' : 'grey.200',
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    textTransform: 'none',
                                    mb: 2,
                                    textAlign: 'left',
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Box sx={{ color: selectedItemIndex === index ? 'primary.main' : 'grey.300' }}>
                                        {icon}
                                    </Box>
                                    <Box>
                                        <Typography color="text.primary" variant="body2" fontWeight="bold">
                                            {title}
                                        </Typography>
                                        <Typography color="text.secondary" variant="body2">
                                            {description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Button>
                        ))}
                    </Box>
                </Box>
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                    {selectedFeature && (
                        <Card sx={{ p: 4, width: '100%', display: 'flex', flexDirection: 'column', height: '500px' }}>
                            <Box sx={{ width: '100%', height: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
                                <img
                                    src={selectedFeature.image}
                                    alt={selectedFeature.title}
                                    style={{ width: 'auto', height: '100%' }}
                                />
                            </Box>
                            <Box sx={{ flexGrow: 1, mt: 2, height: '40%', overflowY: 'auto' }}>
                                <Typography
                                    color="text.primary"
                                    variant="h5"
                                    fontWeight="bold"
                                    gutterBottom
                                >
                                    {selectedFeature.title}
                                </Typography>
                                <Typography color="text.secondary" variant="body1">
                                    {selectedFeature.description}
                                </Typography>
                            </Box>
                        </Card>
                    )}
                </Box>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' }, mb: 4 }}>
                {items[language] && items[language].map(({ icon, title, description }, index) => (
                    <Button
                        key={index}
                        variant="outlined"
                        onClick={() => handleItemClick(index)}
                        sx={{
                            p: 2,
                            background: 'none',
                            backgroundColor: selectedItemIndex === index ? 'action.selected' : undefined,
                            borderColor: selectedItemIndex === index ? 'primary.light' : 'grey.200',
                            width: '100%',
                            justifyContent: 'flex-start',
                            textTransform: 'none',
                            mb: 2,
                            textAlign: 'left',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Box sx={{ color: selectedItemIndex === index ? 'primary.main' : 'grey.300' }}>
                                {icon}
                            </Box>
                            <Box>
                                <Typography color="text.primary" variant="body2" fontWeight="bold">
                                    {title}
                                </Typography>
                                <Typography color="text.secondary" variant="body2">
                                    {description}
                                </Typography>
                            </Box>
                        </Box>
                    </Button>
                ))}
            </Box>
        </Container>
    );
};

export default Features;