import React, { useContext, useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Box, Button, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { LanguageContext } from '../../LanguageContext';

const Header = ({ mode, toggleMode, showMenu, showAuthButtons }) => {
    const { language, toggleLanguage } = useContext(LanguageContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isBusinessPage, setIsBusinessPage] = useState(false);
    const [isHomePage, setIsHomePage] = useState(false);

    useEffect(() => {
        const pathname = window.location.pathname;
        setIsBusinessPage(pathname.includes('/business'));
        setIsHomePage(pathname === '/' || pathname === '/index.html');
    }, []);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItems = [
        { label: language === 'EN' ? 'Features' : '機能', href: isBusinessPage ? '/business#features' : '/personal#features' },
        { label: language === 'EN' ? 'Testimonials' : 'お客様の声', href: isBusinessPage ? '/business#testimonials' : '/personal#testimonials' },
        { label: language === 'EN' ? 'Pricing' : '価格', href: isBusinessPage ? '/business#pricing' : '/personal#pricing' },
        { label: language === 'EN' ? 'FAQ' : 'よくある質問', href: isBusinessPage ? '/business#faq' : '/personal#faq' },
        { label: language === 'EN' ? 'Wiki' : '使い方', href: 'https://wiki.tenno.ai/', target: '_blank', rel: 'noopener noreferrer' },
        {
            label: isBusinessPage
                ? language === 'EN' ? 'For Individuals' : '個人の方はこちら'
                : language === 'EN' ? 'For Business' : '法人利用はこちら',
            href: isBusinessPage ? '/personal' : '/business'
        },
    ];

    return (
        <AppBar
            position="fixed"
            sx={{
                bgcolor: 'background.default',
                zIndex: 1201,
                boxShadow: 'none',
            }}
        >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <a href="/" style={{ display: 'inline-block', textDecoration: 'none', outline: 'none', WebkitTapHighlightColor: 'transparent' }}>
                        <img
                            src={mode === 'light' ? '/assets/images/lightLogo.svg' : '/assets/images/darkLogo.svg'}
                            alt="logo"
                            style={{
                                height: 30,
                                userSelect: 'none',
                                border: 'none',
                                outline: 'none',
                                display: 'block'
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            onError={(e) => console.error("SVG failed to load:", e)}
                            onLoad={() => console.log("SVG loaded successfully")}
                        />
                    </a>
                    {!isHomePage && showMenu && (
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 2 }}>
                            {menuItems.map((item) => (
                                <Button key={item.href} href={item.href} sx={{ color: 'text.primary' }} target={item.target} rel={item.rel}>
                                    {item.label}
                                </Button>
                            ))}
                        </Box>
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button onClick={toggleLanguage} sx={{ color: 'text.primary', mr: 2 }}>
                        {language === 'EN' ? 'JP' : 'EN'}
                    </Button>
                    {!isHomePage && showAuthButtons && (
                        <>
                            <Button
                                href="https://app.tenno.ai/login"
                                variant="outlined"
                                sx={{
                                    color: 'text.primary',
                                    borderColor: 'text.primary',
                                    mr: 2,
                                    '&:hover': {
                                        backgroundColor: 'action.hover',
                                        borderColor: 'text.primary',
                                    },
                                }}
                            >
                                {language === 'EN' ? 'Sign In' : 'ログイン'}
                            </Button>
                            <Button
                                href="https://app.tenno.ai/register"
                                variant="contained"
                                sx={{
                                    backgroundColor: 'primary.main',
                                    color: 'background.default',
                                    mr: 2,
                                    '&:hover': {
                                        backgroundColor: 'primary.dark',
                                    },
                                }}
                            >
                                {language === 'EN' ? 'Sign Up' : '新規登録'}
                            </Button>
                        </>
                    )}
                    <IconButton
                        edge="end"
                        aria-label="mode"
                        onClick={toggleMode}
                        sx={{
                            height: 30,
                            color: mode === 'light' ? 'text.primary' : 'inherit'
                        }}
                    >
                        {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                    </IconButton>
                    {!isHomePage && showMenu && (
                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                edge="end"
                                aria-label="menu"
                                onClick={handleMenu}
                                sx={{
                                    color: mode === 'light' ? 'text.primary' : 'inherit'
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {menuItems.map((item) => (
                                    <MenuItem key={item.href} onClick={handleClose} component="a" href={item.href} target={item.target} rel={item.rel}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
