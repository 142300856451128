import React, { useContext, useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { LanguageContext } from '../LanguageContext';
import PersonalFooter from './personal/PersonalFooter';

const Contact = () => {
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', overflowY: 'auto' }}>
      <header>
        <Container>
          <Typography component="h1" variant="h4" color="text.primary" gutterBottom>
            {language === 'EN' ? 'Contact' : 'コンタクト'}
          </Typography>
        </Container>
      </header>
      <main style={{ flex: '1 1 0%', padding: '2rem 0' }}>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 'calc(100vh - 200px)',
          }}
        >
          <Typography variant="h5" color="text.primary" textAlign="center" mb={2}>
            {language === 'EN' ? 'Contact Us' : 'お問い合わせ'}
          </Typography>
          <Typography variant="body1" color="text.secondary" textAlign="center" mb={4}>
            {language === 'EN'
              ? 'If you have any questions or inquiries, please fill out the form below.'
              : 'ご質問やお問い合わせがありましたら、以下のフォームにご記入ください。'}
          </Typography>
          <Box
            sx={{
              width: '100%',
              maxWidth: 700,
              height: '600px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              position: 'relative',
            }}
          >
            <div data-tf-live="xR6rQi9p" style={{ position: 'absolute', width: '100%', height: '100%' }}></div>
          </Box>
          <Box mt={4}>
            <a
              href="https://form.typeform.com/to/xR6rQi9p"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'inline-block',
                padding: '1rem 2rem',
                fontSize: '1.2rem',
                backgroundColor: '#1976d2',
                color: '#fff',
                borderRadius: '8px',
                textDecoration: 'none',
                transition: 'background-color 0.3s',
              }}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#115293')}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#1976d2')}
            >
              {language === 'EN' ? 'Contact Us' : 'お問い合わせ'}
            </a>
          </Box>
        </Container>
      </main>
      <footer>
        <Container>
          <PersonalFooter />
        </Container>
      </footer>
    </div>
  );
};

export default Contact;
