import React from 'react';
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CardHeader,
    Avatar,
} from '@mui/material';
import { LanguageContext } from '../../LanguageContext';

const userTestimonials = {
    EN: [
        {
            avatar: <Avatar alt="Customer 1" src="/assets/images/avatar/1.png" />,
            title: 'Software Engineer',
            testimonial:
                "Tenno AI helps me stay on top of my tasks and improves my focus. The insights from AI are incredibly helpful for making better decisions.",
        },
        {
            avatar: <Avatar alt="Customer 2" src="/assets/images/avatar/2.png" />,
            title: 'Project Manager',
            testimonial:
                "As a project manager, Tenno AI has transformed the way I handle my projects. The team collaboration features are a huge plus.",
        },
        {
            avatar: <Avatar alt="Customer 3" src="/assets/images/avatar/3.png" />,
            title: 'Marketing Specialist',
            testimonial:
                "I'm really glad I started using Tenno AI. It's more than just a tool; it's a community that supports my professional growth.",
        },
    ],
    JP: [
        {
            avatar: <Avatar alt="Customer 1" src="/assets/images/avatar/1.png" />,
            title: 'ソフトウェアエンジニア',
            testimonial:
                "Tenno AIのおかげで、仕事の効率が上がりました。特に、使いやすいインターフェースが気に入っています。",
        },
        {
            avatar: <Avatar alt="Customer 2" src="/assets/images/avatar/2.png" />,
            title: 'プロジェクトマネージャー',
            testimonial:
                "Tenno AIを導入してから、タスク管理がとても楽になりました。シンプルで直感的な操作が助かっています。",
        },
        {
            avatar: <Avatar alt="Customer 3" src="/assets/images/avatar/3.png" />,
            title: 'マーケティングスペシャリスト',
            testimonial:
                "Tenno AIを使うことで、AIの知識が深まりました。コミュニティでのサポートも非常に役立っています。",
        },
    ],
};

const Testimonials = () => {
    const { language } = React.useContext(LanguageContext);

    return (
        <Container
            id="testimonials"
            sx={{
                py: { xs: 8, sm: 16 },
            }}
        >
            <Typography
                component="h2"
                variant="h4"
                color="text.primary"
                textAlign="left"
                mb={8}
            >
                {language === 'EN' ? 'Testimonials' : 'お客様の声'}
            </Typography>
            <Grid container spacing={4}>
                {userTestimonials[language].map((testimonial, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <CardContent>
                                <Typography variant="body1" color="text.secondary">
                                    {testimonial.testimonial}
                                </Typography>
                            </CardContent>
                            <CardHeader
                                avatar={testimonial.avatar}
                                title={testimonial.title}
                                sx={{ alignSelf: 'flex-end', mr: 2, mb: 2 }}
                            />
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Testimonials;
