import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Hero from './components/business/Hero';
import Features from './components/business/Features';
import Testimonials from './components/business/Testimonials';
import Pricing from './components/business/Pricing';
import FAQ from './components/business/FAQ';
import VideoSection from './components/business/VideoSection'; // 新しく追加
import { LanguageProvider } from './LanguageContext';
import './App.css';

const TennoBusiness = () => {
    const [mode, setMode] = React.useState(() => {
        return localStorage.getItem('mode') || 'light';
    });

    const theme = createTheme({
        palette: {
            mode,
            background: {
                default: mode === 'light' ? '#fff' : '#000',
            },
            text: {
                primary: mode === 'light' ? '#000' : '#fff',
            },
        },
    });

    const toggleMode = () => {
        const newMode = mode === 'light' ? 'dark' : 'light';
        setMode(newMode);
        localStorage.setItem('mode', newMode);
    };

    useEffect(() => {
        const savedMode = localStorage.getItem('mode');
        if (savedMode) {
            setMode(savedMode);
        }
    }, []);

    useEffect(() => {
        document.title = 'Tenno AI Enterprise Edition';
    }, [mode]);

    return (
        <LanguageProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="App">
                    <Header mode={mode} toggleMode={toggleMode} showMenu={true} />
                    <main className="main-content">
                        <section id="hero">
                            <Hero />
                        </section>
                        <section id="video">
                            <VideoSection />
                        </section>
                        <section id="features">
                            <Features />
                        </section>
                        <section id="testimonials">
                            <Testimonials />
                        </section>
                        <section id="pricing">
                            <Pricing />
                        </section>
                        <section id="faq">
                            <FAQ />
                        </section>
                    </main>
                    <Footer />
                </div>
            </ThemeProvider>
        </LanguageProvider>
    );
};

export default TennoBusiness;
